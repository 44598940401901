html, body{
  height:100%;
  width:100%;
  margin:0;
  font-family: 'SF UI Display', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Segoe UI', 'Pingfang SC', 'Lantinghei SC', 'Microsoft Yahei', 'Hiragino Sans GB', Arial, sans-serif !important;
  font-size: 12px;
}

#root {
  height:100%;
  background: #f5f5f5;

  .dark {
    background: #313131;
  }
}

.container {  
  display: flex;
  flex-direction: column;
  height:100%;
  padding: 0 !important;

}

  /* header */
  .header{
    flex: 1;
    display: flex;
    align-items: center;
    padding: 10px 20px;

    img{
      height: 40px;
      margin-right: 10px
    }  
  }

  /* message */
  .messages{
    flex: 10;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;

    .item {
      margin: 0 13px;
    }
    
    .avatar {
      width: 50px;
      height: 50px;
    }  

    .thumbnail{
      width: 130px;
      min-height: 130px;
      margin-top: 10px;
      border-radius: 10px;
      object-fit: cover;
      cursor: pointer;
    }

    .info {
      flex-direction: column;
    
      span {
        display: block;
        opacity: 0.5;
        margin-top: 20px;        
      }
    
      p {
        display: inline-block;
        padding: 8px 12px;
        border-radius: 5px;
        max-width: 65%;
        margin-top: 8px; 
        word-break: break-word;
        position: relative;
      }
    }
    
    .sent{
      .avatar {
        float: left;
      }
    
      .msg {
        background: #ffffff;
        box-shadow: 0 2px rgba(0, 0, 0, 0.16);
        color:#4b4b4b;
      }
    
      .msg::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        top: 10px;
        left: -4px;
        transform: rotate(135deg);
        background: #ffffff;
        border: 1px #ffffff;
        border-style: solid solid none none;
        box-shadow: 2px 0px rgba(0, 0, 0, 0.16);    
      }

      .dark{
        background: #4b4b4b !important;
        color: #ffffff;
      }  
      
      .dark::before{
        background: #4b4b4b;
        border: 1px #4b4b4b;
        border-style: solid solid none none;
        box-shadow: 2px 0px #000000;    
      }   
    
    }
    
    .replies{
      .avatar {
        float: right;
      }
    
      .thumbnail{
        float: right;
      }
    
      span{
        text-align: right;
      }
    
      .msg {
        background: #4b4b4b;
        box-shadow: 0 2px #000000;
        color: #ffffff;
        float: right;
      }

      .msg:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        top: 8px;
        right: -3px;
        transform: rotate(45deg);
        background: #4b4b4b;
        border: 1px #4b4b4b;
        border-style: solid solid none none;
        box-shadow: 2px 0px #000000;    
      }

      .dark{
        background:  #ffffff!important;
        color:#4b4b4b;
      }  

      .dark::after{
        background: #ffffff;
        border: 1px #ffffff;
        border-style: solid solid none none;
        box-shadow: 2px 0px rgba(0, 0, 0, 0.16);          
      }   
    }  

    /* auto msg */
    .auto{
      border: 1px solid #4B4B4B;
      padding: 5px 20px;
      border-radius: 20px;
      color: #4B4B4B;
    }

    /* system msg */
    .system{
      margin: 25px 22px;
      font-size: 10px;
      color: #888888;
      text-align: center;
    }    
  }

  /* footer */
  .footer{
    flex: 1;
    padding: 2px 20px;
    display: flex;
    align-items: center;
    min-height: 60px;
    border: solid 1px #ffffff;
    background: #f8f8f8;

    input{
      flex: 1;
      border-radius: 5px;
      padding: 10px;
      background-color: #dedede;
      box-shadow: 0 -1px 0 0 #afafaf;
    }

    label{ 
      margin-bottom: 0;
    }

    img{
      margin-left: 5px;
      cursor: pointer;
      border-radius: 5px;
      border: solid 1px #ffffff;
      box-shadow: 0px -1px  #afafaf, 0px -1px  #afafaf inset;
    }
  } 

  .footer_dark{
    background:#313131 !important;
    border: solid 1px #5d5d5d;

    input{
      background-color: #1e1e1e;
      box-shadow: 0 -1px 0 0 #000000;
      color: #ffffff;
      
    }  
    
    img{
      border: solid 1px #5d5d5d;
      box-shadow: 0px -1px  #000000, 0px -1px  #000000 inset;
    }    
  }

/* rate */
.rate{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .top{
    margin: 20px auto;
  }

  .title{
    margin: 20px auto;
  }

  .satisfy{
    width: 64px;
    height: 64px;
    margin: 0 22px 30px;
  }  

  .detail{
    padding: 0 26px;
    width: 100%;

    textarea{
      flex: 1;
      border-radius: 10px;
      padding: 10px;
      background-color: #dedede;
      border-top: 1px solid #888888;
      box-shadow: 0 1px #fff, 0 -1x #4B4B4B inset;

    }

  }

  .selectItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    margin-bottom: 12px;
    box-shadow: 0 1px #fff, 0 -1px #d0d0d0 inset;
    height: 30px;

    /* No engrave for the last item */
    &:last-of-type { 
      box-shadow: none; 
      margin-bottom: 0;
    }       

    img{
      width: 26px;
      height: 26px;
    }
  }

  button{
    width: 100%;
    height: 40px;
    font-size: 16px;
  }

  /* disable bootstrap style */
  border: none;

}  

/* common css */
input:focus {
  outline: none;
}

input {
  border: none;
}

.btn{
  color: #fff;
  min-height: 31px;
  border-radius: 6px !important;
  background-color: #4B4B4B !important;
  border: 1px solid #fff !important;
  box-shadow: 0px -1px #D0D0D0, 0px -1px #D0D0D0 inset;
}

.o-5 { opacity: 0.5; }

.center{ text-align: center; }

/* Loading */
.loading {
  width: 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

p{
  margin-bottom: 0;
}

/* font */
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 600 !important;
  color: #4b4b4b;
  margin-bottom: 0px; /* disable bootstrap css */
}

.h1 { font-size: 36px; } 
.h2 { font-size: 30px; } 
.h3 { font-size: 24px; } 
.h4 { font-size: 20px; } 
.h5 { font-size: 16px; }
.h6 { font-size: 12px;} 

// Special Definition for h6 class
.h6 {
  letter-spacing: 0.6px;
  opacity: 0.8;
  font-weight: 500 !important;
}